@import '../../../styles/colors';
@import '../../../styles/mixin';

.LoaderBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba($grey-900, 0.8);
  background: url('../../../assets/loader.svg') no-repeat center center;
  background-size: cover;
  @extend .backdrop;
  z-index: $zi-page-loader;
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
}

.Logo {
  width: 200px;
  margin-bottom: 20px;
}

.Loader {
  width: 200px;
  position: relative;
  padding: 2px;
}

.LoaderBar {
  position: absolute;
  @include border-radius(2px);
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: $white;
  width: 0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
