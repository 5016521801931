@import 'colors';
@import 'mixin';

.container {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  @extend .bsbb;
}

.rw {
  @extend .responsive-width;
  @extend .bsbb;
}

h1 {
  font-family: $title-font-family;
  margin-bottom: 20px;
  @extend .bsbb;
  font-size: 36px;
  width: 100%;
  line-height: 1;
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

h2 {
  font-family: $title-font-family;
  text-transform: uppercase;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 300;
  @extend .bsbb;
  position: relative;
  padding-left: 50px;
  line-height: 1;
  &:not(:first-of-type) {
    margin-top: 20px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 40px;
    height: 2px;
    background: $blue;
  }
}

h3 {
  font-weight: 600;
  font-size: 18px;
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}
