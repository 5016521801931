@import 'colors';
@import 'mixin';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $bkg;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 16px;
  font-family: $common-font-family;
  vertical-align: top;
  font-weight: 400;
  color: $text-color;
  border: 0;
  outline: none;
  line-height: 1.5;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: $selection;
  color: $text-selection;
}

::-moz-selection {
  background-color: $selection;
  color: $text-selection;
}

button {
  cursor: pointer;
  background: transparent;
}

:disabled {
  cursor: not-allowed;
  @include opacity(0.5);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $text-color;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $text-color;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $text-color;
  opacity: 0.5;
}

::-webkit-scrollbar {
  display: none;
}

textarea {
  min-height: 250px;
}

button {
  padding: 0;
}

label {
  @extend .display-flex;
  @extend .flex-direction-column;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  width: 100%;
  input,
  textarea,
  select {
    margin-top: 5px;
  }
  &:not(:first-of-type) {
    padding-top: 20px;
  }
  &.npt {
    padding-top: 0;
  }
}

input,
textarea,
select {
  border: 1px solid $grey-300;
  background: $card;
  padding: 10px;
  @extend .bsbb;
  height: 42px;
  width: 100%;
  background: transparent;
  @include border-radius(5px);
  color: $text-color;
  &:focus {
    border-color: $blue;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $white;
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $white;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $white;
  opacity: 0.5;
}

textarea {
  min-height: 200px;
}
